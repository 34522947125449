
import GeneralData from "@/modules/pettyCash/components/TransferBetweenGeneralData.vue";
import useTransferBetween from "@/modules/pettyCash/composables/useTransferBetween";
import toastr from "toastr";
import {onMounted, ref} from "vue";
import router from "@/router";

export default {
    props: ['id', 'transferId'],
    components: {GeneralData},
    setup(props: { id: string, transferId: string }) {
        const {
            clearForm,
            transferBetweenForm,
            updateTransferBetween,
            catalogs,
            getTransferBetween
        } = useTransferBetween()
        const sending = ref(false)
        const loading = ref(true)

        clearForm();

        onMounted(async () => {
            transferBetweenForm.value = await getTransferBetween(props.transferId)
            loading.value = false
        })

        return {
            mode: 'EDIT',
            sending,
            loading,
            catalogs,
            cancel: () => router.push({name: 'petty_cash.edit', params: {id: props.id}}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await updateTransferBetween(transferBetweenForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'petty_cash.edit', params: {id: props.id}})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
